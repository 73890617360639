import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { HelmetDatoCms } from 'gatsby-source-datocms';

import { FormattedMessage } from 'react-intl';

import Layout from '../components/layout';

import Menu from '../components/menu';
import EDialog from '../components/eDialog';
import Headline from '../components/headline';
import LanguageSwitch from '../components/languageSwitch';
import { getLocalizedPath } from '../core/i18n';

import StyledButton from '../style/common/styledButton';
import StyledWrapper from '../style/home/styledWrapper';
import StyledContentWrapper from '../style/home/styledContentWrapper';
import StyledContent from '../style/home/styledContent';
import StyledImage from '../style/home/styledImage';
import StyledFooterCredits from '../style/footer/styledFooterCredits';
import StyledEWink from '../style/home/styledEWink';
import SignatureEuricom from '../assets/images/signature-euricom.png';
import SignatureFacebook from '../assets/images/signature-facebook.png';
import SignatureInstagram from '../assets/images/signature-instagram.png';
import SignatureTwitter from '../assets/images/signature-twitter.png';
import SignatureLinkedin from '../assets/images/signature-linkedin.png';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eToggle: false,
      bootcampToggle: false,
    };
  }

  toggleEDialog = () => {
    this.setState(prevState => ({ eToggle: !prevState.eToggle }));
  };

  toggleBootcampDialog = () => {
    this.setState(prevState => ({ bootcampToggle: !prevState.bootcampToggle }));
  };

  render() {
    const { home, globalInfo } = this.props.data;
    const { headline } = home;
    const { locale } = this.props.pageContext;
    const jobsUrl = getLocalizedPath('/jobs', locale);

    return (
      <Layout location={this.props.location}>
        <HelmetDatoCms seo={home.seoMetaTags} />
        <EDialog jobsUrl={jobsUrl} toggleState={this.state.eToggle} toggle={this.toggleEDialog} />
        <StyledEWink onClick={this.toggleEDialog} />
        <StyledImage>
          <Img sizes={home.backgroundImage.sizes} />
          <div className="hidden-signature">
            <img src={SignatureEuricom} />
            <img src={SignatureFacebook} />
            <img src={SignatureInstagram} />
            <img src={SignatureLinkedin} />
            <img src={SignatureTwitter} />
          </div>
        </StyledImage>
        <StyledWrapper>
          {headline.title && <Headline title={headline.title.md.html} slug={headline.slug} locale={locale} />}
          <Menu activeHeadline />
          <StyledContentWrapper>
            <StyledContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: home.title.md.html,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: home.subTitle.md.html,
                }}
              />
              <div>
                <a href={getLocalizedPath('/clients', locale)}>
                  <StyledButton large color="#fff">
                    <FormattedMessage id={'home.workingTogether'} />
                  </StyledButton>
                </a>
                <a href={getLocalizedPath('/jobs', locale)}>
                  <StyledButton large>
                    <FormattedMessage id={'home.workingAt'} />
                  </StyledButton>
                </a>
              </div>
            </StyledContent>
          </StyledContentWrapper>
          <StyledFooterCredits transparent>
            {globalInfo.hasInternationalization && <LanguageSwitch locale={locale} location={this.props.location} />}
          </StyledFooterCredits>
        </StyledWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
  query HomePageQuery($locale: String!) {
    home: datoCmsHome(locale: { eq: $locale }) {
      headline {
        title: titleNode {
          md: childMarkdownRemark {
            html
          }
        }
        slug
      }
      title: titleNode {
        md: childMarkdownRemark {
          html
        }
      }
      subTitle: subTitleNode {
        md: childMarkdownRemark {
          html
        }
      }
      backgroundImage {
        sizes(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    globalInfo: datoCmsGlobalInfo {
      hasInternationalization
    }
  }
`;

export default HomePage;

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
